exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-box-shadow-css-generator-js": () => import("./../../../src/pages/box-shadow-css-generator.js" /* webpackChunkName: "component---src-pages-box-shadow-css-generator-js" */),
  "component---src-pages-daily-color-js": () => import("./../../../src/pages/daily-color.js" /* webpackChunkName: "component---src-pages-daily-color-js" */),
  "component---src-pages-filter-css-generator-js": () => import("./../../../src/pages/filter-css-generator.js" /* webpackChunkName: "component---src-pages-filter-css-generator-js" */),
  "component---src-pages-form-input-html-css-generator-js": () => import("./../../../src/pages/form-input-html-css-generator.js" /* webpackChunkName: "component---src-pages-form-input-html-css-generator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tailwind-to-css-js": () => import("./../../../src/pages/tailwind-to-css.js" /* webpackChunkName: "component---src-pages-tailwind-to-css-js" */)
}

